@import "../../../styles/_chunk";

.bundlefest-countdown {
  background-color: $black;
  min-height: 100px;
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 1rem;
  position: relative;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0 2rem 0;
    padding: 0.5rem 1.5rem;
  }

  @include media-breakpoint-up(xxl) {
    margin: 3rem 0 2rem 0;
    padding: 0.5rem 2rem;
  }

  &.bundlepage {
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 0.5rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-bottom: 1rem;
    }
  }

  .logo-container {
    position: relative;
    z-index: 1;
    width: 260px;
    height: 19px;
    flex-shrink: 0;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      width: 340px;
      height: 25px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      width: 320px;
    }

    @include media-breakpoint-up(lg) {
      width: 340px;
    }

    @include media-breakpoint-up(xl) {
      width: 370px;
      height: 27px;
    }

    @include media-breakpoint-up(xxl) {
      width: 400px;
      height: 29px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .timer-text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .text-container {
      color: $white;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0.75rem;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }

      @include media-breakpoint-up(md) {
        font-size: 16px;
        margin-bottom: 0.5rem;
        margin-top: -0.5rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 1rem;
        font-size: 18px;
      }
    }

    .timer-container {
      .sd-timer {
        background-color: $white;
        color: #26033d;
        padding: 0.5rem 1rem;
        font-size: 20px;
        // border-radius: $border-radius-sm;
        font-weight: 700;

        @include media-breakpoint-up(xl) {
          font-size: 22px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 24px;
        }

        span.colon {
          color: #ee37cf;
          margin: 0 0.5rem;
          font-size: 24px;
          line-height: 24px;

          @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 26px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 28px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
